// /* You can add global styles to this file, and also import other style files */

$background-primary: #ffffff;
$background-secondary: linear-gradient(#aba8dd, #55dfe0);

$clr-text-primary: #15154c;
$clr-text-primary-muted: #039f9f;
$clr-text-secondary: #6f6f6f;
$clr-text-tertiary: #ffffff;

$clr-text-muted: #e8e8e8;
$clr-text-placeholder: #d2d2d2;
$clr-text-error: #eb5757;

$clr-bg-primary: #ffffff;
$clr-bg-secondary: linear-gradient(#aba8dd, #55dfe0);

$border-separator: 1px solid #aaa9dd;

$clr-border-focussed: #5a55d2;
$clr-border-success: #219653;
$clr-border-error: #eb5757;
$clr-border-primary: #e8e8e8;
$clr-border-secondary: #c4c4c4;

$border-primary: 1px solid $clr-text-primary;
$border-secondary: 1px solid $clr-border-secondary;

$clr-btn-primary: #15154c;
$clr-btn-secondary: #15154c; // TBD
$clr-btn-cancel: #e8e8e8; // TBD
$clr-btn-disabled: #e8e8e8; // TBD

// define fonts
$ft-family-primary: "Poppins";
$ft-family-secondary: "Poppins"; // TBD

$ft-thin: 100;
$ft-extralight: 200;
$ft-light: 300;
$ft-normal: 400;
$ft-medium: 500;
$ft-semi-bold: 600;
$ft-bold: 700;
$ft-extrabold: 800;

$ft-size-primary: 14px;
$ft-size-secondary: 12px;
$ft-size-header: 15px;

$ft-size-regular: 14px;
$ft-size-small: 12px;
$ft-size-xs: 10px;
$ft-size-large: 21px;
$ft-size-medium: 18px;
