$background-primary-adminui: #ffffff;
$background-secondary-adminui: #eff3fd;
$background-tertiary-adminui: #f3f3ff;

$clr-text-primary-adminui: #192a3e;
$clr-text-secondary-adminui: #15154c;
$clr-text-tertiary-adminui: #333333;
$clr-text-quarternary-adminui: #ffffff;
$clr-text-header-adminui: #00140e;
$clr-text-sub-header-adminui: #5b5f7b;
$clr-text-input-adminui: #6f6f6f;

$clr-text-muted-adminui: #e8e8e8;
$clr-text-placeholder-adminui: #d2d2d2;
$clr-text-error-adminui: #eb5757;

$clr-bg-primary-adminui: #ffffff;
$clr-bg-secondary-adminui: linear-gradient(#aba8dd, #55dfe0);

$border-separator-adminui: 1px solid #e4e7ed;

$clr-border-focussed-adminui: #5a55d2;
$clr-border-success-adminui: #219653;
$clr-border-error-adminui: #eb5757;
$clr-border-primary-adminui: #e8e8e8;
$clr-border-secondary-adminui: #c4c4c4;

$border-primary-adminui: 1px solid $clr-text-primary-adminui;
$border-secondary-adminui: 1px solid $clr-border-secondary-adminui;
$border-divider-adminui: 1px solid $background-secondary-adminui;

$border-radius-regular-adminui: 6px;
$border-radius-medium-adminui: 8px;
$border-radius-large-adminui: 10px;
$border-radius-xl-adminui: 16px;

$clr-btn-primary-adminui: #15154c;
$clr-btn-secondary-adminui: #15154c; // TBD
$clr-btn-cancel-adminui: #e8e8e8; // TBD
$clr-btn-disabled-adminui: #e8e8e8; // TBD

// define fonts
$ft-family-primary-adminui: "Poppins";
$ft-family-secondary-adminui: "Poppins"; // TBD

$ft-thin-adminui: 100;
$ft-extralight-adminui: 200;
$ft-light-adminui: 300;
$ft-normal-adminui: 400;
$ft-medium-adminui: 500;
$ft-semi-bold-adminui: 600;
$ft-bold-adminui: 700;
$ft-extrabold-adminui: 800;

$ft-size-regular-adminui: 14px;
$ft-size-small-adminui: 12px;
$ft-size-xs-adminui: 10px;
$ft-size-large-adminui: 21px;
$ft-size-medium-adminui: 18px;
